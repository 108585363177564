import ProductionPartNoticeStatus from './enums/ProductionPartNoticeStatus';
import ImportError from './ImportError';

export default class ProductionPartNotice implements ImportError {
    public id: number = 0;

    public partNumber: string = '';

    public fromLocation: string = '';

    public toLocation: string = '';

    public effectiveDateFrom?: Date;

    public effectiveDateTo?: Date;

    public containerItemNumber: string = '';

    public quantityPerContainer: number = 0;

    public palletized: boolean = false;

    public palletContainerItemNumber?: string;

    public containersPerPallet?: number;

    public includeTopCap: boolean = false;

    public topCapItemNumber?: string;

    public enabled: boolean = false;

    public status: ProductionPartNoticeStatus = ProductionPartNoticeStatus.Pending;

    constructor(init: Partial<ProductionPartNotice>) {
        Object.assign(this, init);

        if (init.effectiveDateFrom) {
            this.effectiveDateFrom = new Date(init.effectiveDateFrom);
        }

        if (init.effectiveDateTo) {
            this.effectiveDateTo = new Date(init.effectiveDateTo);
        }
    }
}
