import ShippingNotice from '@/domain/ShippingNotice';
import ShippingNoticeApiService from './api/ShippingNoticeApiService';
import ImportErrorService from './ImportErrorService';
import { getTranslation, getTitleCaseTranslation } from './TranslationService';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class ShippingNoticeService extends ImportErrorService<ShippingNotice> {
    private apiService: ShippingNoticeApiService = new ShippingNoticeApiService();

    private readonly revalidationSuccessMsg = getTranslation('core.common.asnHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly');

    public async getAllFailed(): Promise<ShippingNotice[]> {
        const response = await this.apiService.getFailedShippingNotices();

        return response.data;
    }

    public async getAllFailedCount(): Promise<number> {
        const count = await this.apiService.getFailedShippingNoticesCount();
    
        return count;
    }

    public async rejectFailed(id: number): Promise<number> {
        const response = await this.apiService.rejectShippingNotice(id);

        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.rejectedShippingNotice'));
        }

        return response.data;
    }

    public async revalidateAllFailed(): Promise<number> {
        const response = await this.apiService.revalidateAllShippingNotices();

        return this.getRevalidateAllResult(response, this.revalidationSuccessMsg, getTranslation('core.validation.allItemsContainOneOrMoreValidationErrorsThatNeedAddressing'));
    }

    public async revalidateFailed(id: number): Promise<string[]> {
        const response = await this.apiService.revalidateShippingNotice(id);

        return this.getRevalidateResult(response, this.revalidationSuccessMsg);
    }

    public async getShippingNotice(id: number): Promise<ShippingNotice> {
        const response = await this.apiService.getShippingNotice(id);
        return response.data;
    }

    public async updateShippingNotice(shippingNotice: ShippingNotice): Promise<DataAccessResponse<boolean>> {
        const response = await this.apiService.updateShippingNotice(shippingNotice);

        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedNotice'));
        }

        return response;
    }
}
