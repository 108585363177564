import { useNotification } from '@/composable/useNotifications';
import ImportError from '@/domain/ImportError';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default abstract class ImportErrorService<T extends ImportError> {
    protected notification = useNotification();

    protected getRevalidateAllResult(response: DataAccessResponse<number>, successMsg: string, errorMsg: string) : number {
        if (response.success) {
            if (response.data > 0) {
                this.notification.showSuccess(successMsg, undefined, 5000);
            } else {
                this.notification.showError(errorMsg);
            }
        }

        return response.data;
    }

    protected getRevalidateResult(response: DataAccessResponse<string[]>, successMsg: string): string[] {
        if (response.success && !response.data.length) {
            this.notification.showSuccess(successMsg, undefined, 5000);
        }

        return response.data;
    }

    public abstract getAllFailed(): Promise<T[]>;

    public abstract rejectFailed(id: number): Promise<number>;

    public abstract revalidateAllFailed(): Promise<number>;

    public abstract revalidateFailed(id: number): Promise<string[]>;
}
