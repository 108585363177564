

import {
    defineComponent, reactive, onBeforeMount, ref, Ref,
} from 'vue';
import MasterDataSideBar from '@/modules/master-data/routes/MasterDataSideBar';
import SideBarNav from '@/components/sidebar/SideBarNav.vue';
import SideBarMenuItem from '@/components/sidebar/domain/SideBarMenuItem';
import coreStore from '@/store/CoreStore';
import SideBarMenuChild from '@/components/sidebar/domain/SideBarMenuChild';

export default defineComponent({
    name: 'master-data',
    components: {
        SideBarNav,
    },
    props: {},
    setup() {
        const menuItems: SideBarMenuItem[] = reactive([]);
        const pushNotificationCounts = ref(new Map<string, Ref<number>>());
        const { pushNotificationStore } = coreStore.getInstance();
        const sideBar = new MasterDataSideBar();

        const promiseQueue: Promise<{ key: string; initialValue: number }>[] = [];

        async function subscribeAllChildren(sideBarMenuItem: SideBarMenuChild) {
            if (sideBarMenuItem.pushNotificationSubscription) {
                promiseQueue.push((async () => ({
                    key: sideBarMenuItem.pushNotificationSubscription!.key,                        
                    initialValue: await (sideBarMenuItem.pushNotificationSubscription!.initializationCallback?.() || 0),
                }))());                
            }

            sideBarMenuItem.children?.filter((child) => !!child.pushNotificationSubscription).map((child) => subscribeAllChildren(child));               
        }

        async function subscribePushNotification(sideBarMenuItems: SideBarMenuItem[]): Promise<void> {
            const counts = new Map<string, Ref<number>>();

            sideBarMenuItems.map(async (item) => subscribeAllChildren(item));

            const initialValues = await Promise.all(promiseQueue);

            initialValues.forEach((keyValue) => {
                const countRef = pushNotificationStore.publish(keyValue.key, keyValue.initialValue);
                counts.set(keyValue.key, countRef);
            });

            pushNotificationCounts.value = counts;
        }

        onBeforeMount(() => {
            sideBar.updateMenuItems();
            menuItems.push(...sideBar.menuItems);
            subscribePushNotification(menuItems);
        });

        return {
            menuItems,
            pushNotificationCounts,
        };
    },
});
