export default class PushNotificationSubscription {
    public key: string;

    public initializationCallback?: () => Promise<number>;

    constructor(key: string, initializationCallback?: () => Promise<number>) {
        this.key = key;
        this.initializationCallback = initializationCallback;
    }
}
