import ProductionPartNotice from '@/domain/ProductionPartNotice';
import ProductionPartNoticeApiService from './api/ProductionPartNoticeApiService';
import ImportErrorService from './ImportErrorService';
import { getTranslation, getTitleCaseTranslation } from './TranslationService';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class ProductionPartNoticeService extends ImportErrorService<ProductionPartNotice> {
    private apiService: ProductionPartNoticeApiService = new ProductionPartNoticeApiService();

    private readonly revalidationSuccessMsg = getTranslation('core.common.pfepHaveBeenQueuedUpForCreationAndShouldBeAvailableInTheSystemShortly');

    public async getAllFailed(): Promise<ProductionPartNotice[]> {
        const response = await this.apiService.getAllFailedProductionPartNotices();

        return response.data;
    }

    public async getAllFailedCount(): Promise<number> {
        const count = await this.apiService.getAllFailedProductionPartNoticesCount();

        return count;
    }

    public async rejectFailed(id: number): Promise<number> {
        const success = await this.apiService.rejectFailedProductionPartNotice(id);

        if (success) {
            this.notification.showSuccess(getTranslation('core.common.rejectedPFEP'));
        }

        return success ? 1 : 0;
    }

    public async revalidateAllFailed(): Promise<number> {
        const response = await this.apiService.revalidateAllFailedProductionPartNotices();

        return this.getRevalidateAllResult(response, this.revalidationSuccessMsg, getTranslation('core.validation.allPFEPsContainOneOrMoreValidationErrorsThatNeedAddressing'));
    }

    public async revalidateFailed(id: number): Promise<string[]> {
        const response = await this.apiService.revalidateFailedProductionPartNotice(id);

        return this.getRevalidateResult(response, this.revalidationSuccessMsg);
    }

    public async getProductionPartNotice(id: number): Promise<ProductionPartNotice> {
        const response = await this.apiService.getProductionPartNotice(id);
        return response.data;
    }

    public async updateProductionPartNotice(notice: ProductionPartNotice): Promise<DataAccessResponse<boolean>> {
        const response = await this.apiService.updateProductionPartNotice(notice);

        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedNotice'));
        }

        return response;
    }
}
