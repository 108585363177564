const NotificationStoreEvents = {
    MASTER_DATA: {
        IMPORT_ERRORS: {
            ALL: 'master-data-import-errors',
            ASN: 'master-data-import-errors:asn',
            IDF: 'master-data-import-errors:idf',
            PFEP: 'master-data-import-errors:pfep',
            PARTNER_ORDER: 'master-data-import-errors:po',
        },
    },
};

export default NotificationStoreEvents;
