import ShippingNoticeStatus from './enums/ShippingNoticeStatus';
import ImportError from './ImportError';
import ShippingNoticeItem from './ShippingNoticeItem';

export default class ShippingNotice implements ImportError {
    public id: number;

    public fromLocation: string;

    public toLocation: string;

    public shipDate: Date;

    public carrier: string;

    public trailerNumber: string;

    public partnerReferenceNumber: string;

    public carrierReferenceNumber?: string;

    public licensePlateNumber?: string;

    public seal?: string;

    public billToLocation?: string;

    public createdOn: Date;

    public changedOn: Date;

    public shippingNoticeItems: ShippingNoticeItem[];

    constructor(init?: Partial<ShippingNotice>) {
        this.id = init?.id || 0;
        this.fromLocation = init?.fromLocation || '';
        this.toLocation = init?.toLocation || '';
        this.shipDate = init?.shipDate ? new Date(init.shipDate) : new Date();
        this.carrier = init?.carrier || '';
        this.trailerNumber = init?.trailerNumber || '';
        this.partnerReferenceNumber = init?.partnerReferenceNumber || '';
        this.carrierReferenceNumber = init?.carrierReferenceNumber;
        this.licensePlateNumber = init?.licensePlateNumber;
        this.seal = init?.seal;
        this.createdOn = init?.createdOn ? new Date(init.createdOn) : new Date();
        this.changedOn = init?.changedOn ? new Date(init.changedOn) : new Date();
        this.shippingNoticeItems = init?.shippingNoticeItems ? init.shippingNoticeItems.map((x) => new ShippingNoticeItem(x)) : [];
        this.billToLocation = init?.billToLocation;
    }

    public get failedShippingNoticeItems(): ShippingNoticeItem[] {
        return this.shippingNoticeItems.filter((item) => item.status === ShippingNoticeStatus.Failed);
    }
}
