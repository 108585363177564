import ShippingNoticeStatus from './enums/ShippingNoticeStatus';

export default class ShippingNoticeItem {
    public id: number;

    public shippingNoticeId: number;

    public itemNumber: string;

    public productionPartNumber: string;

    public quantity: number;

    public status: ShippingNoticeStatus;

    public transactionId?: number;

    public createdOn: Date;

    public changedOn: Date;

    constructor(init?: Partial<ShippingNoticeItem>) {
        this.id = init?.id || 0;
        this.shippingNoticeId = init?.shippingNoticeId || 0;
        this.itemNumber = init?.itemNumber || '';
        this.productionPartNumber = init?.productionPartNumber || '';
        this.quantity = init?.quantity || 0;
        this.status = init?.status || ShippingNoticeStatus.Pending;
        this.transactionId = init?.transactionId;
        this.createdOn = init?.createdOn ? new Date(init.createdOn) : new Date();
        this.changedOn = init?.changedOn ? new Date(init.changedOn) : new Date();
    }
}
